'use client';

import { Button, Heading, Text } from '@purposeinplay/core-v2';
import Link from 'next/link';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import Image from 'next/image';
import ContentAreaWrapper from '../ContentAreaWrapper';

const NotFound = () => {
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;

  return (
    <ContentAreaWrapper>
      <div className="relative h-full overflow-hidden rounded-2xl bg-bgr-lighter p-4 lg:py-40">
        <div className="relative z-10 flex flex-col items-center justify-center space-y-4">
          <Image
            width={440}
            height={248}
            fill={false}
            src="/assets/404-dark.svg"
            alt="404"
            className="object-contain"
          />
          <Heading as="h1" className="sr-only z-50 mb-7 md:text-7xl">
            {general?.[404]?.screen_reader_text}
          </Heading>
          <div className="z-50 flex flex-row space-x-1">
            <Text
              size="3xl"
              className="font-medium leading-9 text-base-secondary"
            >
              {general?.[404]?.heading['1st_part']}
            </Text>
            <Text size="3xl" className="font-medium leading-9">
              {general?.[404]?.heading['2nd_part']}
            </Text>
          </div>
          <Link
            className="link-buton link-button--tertiary"
            href="/"
            passHref
            prefetch={false}
          >
            {general?.[404]?.btn_action}
          </Link>
        </div>
      </div>
    </ContentAreaWrapper>
  );
};

export default NotFound;
